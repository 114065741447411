
export default {
  name: 'affiliator-sale',
  props: {
    route: {
      type: String,
      default: '',
    },
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isAffiliatorDefined:false,
      isAffiliatorHome:false,
    };
  },
  mounted() {
    window.customElements.whenDefined('razlet-affiliator').then(() => {
      this.isAffiliatorDefined = true;
    });
  },
};
